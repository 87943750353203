import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import IndexLayout from 'layouts/IndexLayout';
import { Albums, SEO } from 'components';
import { InnerWrap } from '../components/common/Containers/styled';
import { DarkScheme } from 'helpers/navColors.js';

const Filter = styled.div`
  padding: 0 0 40px;
`;

const NewReccsIndex = ({ data, location }) => {
  const { state = {} } = location;
  const {
    allAlbums,
    popAlbums,
    rockAlbums,
    hiphopAlbums,
    electronicAlbums,
    experimentalAlbums,
    folkAlbums,
  } = data;

  let selectedGenre = allAlbums;

  if (state) {
    const { genre } = state;
    switch (genre) {
      case 'Pop':
        selectedGenre = popAlbums;
        break;
      case 'Rock':
        selectedGenre = rockAlbums;
        break;
      case 'Hip-Hop':
        selectedGenre = hiphopAlbums;
        break;
      case 'Electronic':
        selectedGenre = electronicAlbums;
        break;
      case 'Experimental':
        selectedGenre = experimentalAlbums;
        break;
      case 'Folk':
        selectedGenre = folkAlbums;
    }
  }

  const [filteredAlbums, setFilteredAlbums] = useState(selectedGenre);

  const handleChangeFilter = (filter) => {
    setFilteredAlbums(filter);
  };

  return (
    <IndexLayout title='New Reccs' navColor={DarkScheme}>
      <SEO title='New reccs of Japanese music' />
      <InnerWrap>
        <Filter>
          <button
            onClick={() => handleChangeFilter(allAlbums)}
            className={filteredAlbums === allAlbums && 'active'}
          >
            View All
          </button>
          <button
            onClick={() => handleChangeFilter(popAlbums)}
            className={filteredAlbums === popAlbums && 'active'}
          >
            Pop
          </button>
          <button
            onClick={() => handleChangeFilter(rockAlbums)}
            className={filteredAlbums === rockAlbums && 'active'}
          >
            Rock
          </button>
          <button
            onClick={() => handleChangeFilter(hiphopAlbums)}
            className={filteredAlbums === hiphopAlbums && 'active'}
          >
            Hip-Hop
          </button>
          <button
            onClick={() => handleChangeFilter(electronicAlbums)}
            className={filteredAlbums === electronicAlbums && 'active'}
          >
            Electronic
          </button>
          <button
            onClick={() => handleChangeFilter(experimentalAlbums)}
            className={filteredAlbums === experimentalAlbums && 'active'}
          >
            Experimental
          </button>
          <button
            onClick={() => handleChangeFilter(folkAlbums)}
            className={filteredAlbums === folkAlbums && 'active'}
          >
            Folk
          </button>
        </Filter>
        <Albums {...filteredAlbums} />
      </InnerWrap>
    </IndexLayout>
  );
};

export const query = graphql`
  query {
    allAlbums: allStrapiAlbums(sort: { fields: releaseDate, order: DESC }) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
    popAlbums: allStrapiAlbums(
      sort: { fields: releaseDate, order: DESC }
      filter: { genre: { name: { eq: "Pop" } } }
    ) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
    rockAlbums: allStrapiAlbums(
      sort: { fields: releaseDate, order: DESC }
      filter: { genre: { name: { eq: "Rock" } } }
    ) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
    hiphopAlbums: allStrapiAlbums(
      sort: { fields: releaseDate, order: DESC }
      filter: { genre: { name: { eq: "Hip-Hop" } } }
    ) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
    electronicAlbums: allStrapiAlbums(
      sort: { fields: releaseDate, order: DESC }
      filter: { genre: { name: { eq: "Electronic" } } }
    ) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
    experimentalAlbums: allStrapiAlbums(
      sort: { fields: releaseDate, order: DESC }
      filter: { genre: { name: { eq: "Experimental" } } }
    ) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
    folkAlbums: allStrapiAlbums(
      sort: { fields: releaseDate, order: DESC }
      filter: { genre: { name: { eq: "Folk" } } }
    ) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
  }
`;

export default NewReccsIndex;
